const langTrigger = document.querySelector('#lang_select')
let clicked = false

langTrigger.addEventListener('click', function() {
  const langBox = document.querySelector('#lang_select')
  if (!clicked) {
    langBox.classList.remove('lang')
    langBox.classList.add('lang_revealed')
    clicked = true
  }
  else {
    langBox.classList.remove('lang_revealed')
    langBox.classList.add('lang')
    clicked = false
  }
})
