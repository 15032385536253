document.querySelectorAll('.skill_card').forEach(element => {
  element.addEventListener('mouseenter', function() {
    console.log('mouse in')
    element.querySelector('.skill_pic').classList.add('rotate360');
  })
})

document.querySelectorAll('.skill_card').forEach(element => {
  element.addEventListener('mouseleave', function() {
    element.querySelector('.skill_pic').classList.remove('rotate360');
  })
})

