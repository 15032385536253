document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.page_section');
  const navLinks = document.querySelectorAll('.navbar a');

  function highlightLink() {
    let index = sections.length;

    while(--index && window.scrollY + 50 < sections[index].offsetTop) {}

    navLinks.forEach((link) => link.classList.remove('active'));
    const activeId = sections[index].id;
    const activeLink = document.querySelector('.navbar a[href*=' + activeId + ']');
    if (activeLink) {
      activeLink.classList.add('active');
    }
  }

  highlightLink();
  window.addEventListener('scroll', highlightLink);
});
