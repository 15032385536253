console.info("Three.js tests")

import './style/style.scss'

import './skill_logo_anim'

import './section_highlight'

import './lang_select'

import * as THREE from "three";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

// Set the fixed height
const fixedHeight = 500

// Select the container for the canvas, fallback to window width if not found
const container = document.querySelector('.canvas_container')
const viewportWidth = container ? container.clientWidth : window.innerWidth

const cam = new THREE.PerspectiveCamera(
  75,
  viewportWidth / fixedHeight,
  0.1,
  1000
)
cam.position.set(0, 0, 23)

const scene = new THREE.Scene()

const renderer = new THREE.WebGLRenderer({ antialias: true })
// Use the viewport width and the fixed height
renderer.setSize(viewportWidth, fixedHeight)
if (container) {
  container.appendChild(renderer.domElement)
} else {
  document.body.appendChild(renderer.domElement) // Fallback if '.canvas_container' doesn't exist
}

// Adjust renderer and camera on window resize
function onWindowResize() {
    const newWidth = container ? container.clientWidth : window.innerWidth
    cam.aspect = newWidth / fixedHeight
    cam.updateProjectionMatrix()
    renderer.setSize(newWidth, fixedHeight)
}
window.addEventListener('resize', onWindowResize, false)

// Adding a light source behind the doors
const pointLight = new THREE.PointLight(0xffffff, 50, 100)
pointLight.position.set(0, 0, -1) // Adjusted position for better lighting effect
pointLight.castShadow = true
scene.add(pointLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
directionalLight.position.set(0, 0, 15) // Light shining from top
directionalLight.castShadow = true
scene.add(directionalLight)

// Additional ambient light
const ambientLight = new THREE.AmbientLight(0x404040) // Soft white light
scene.add(ambientLight)

// Use BoxGeometry for the doors
const geometry = new THREE.BoxGeometry(5, 10, 0.5) // Depth of 0.5
const material = new THREE.MeshStandardMaterial({ color: 0xFFD700 }) // Material that interacts with light
const doorLeft = new THREE.Mesh(geometry, material)
const doorRight = new THREE.Mesh(geometry, material)

doorLeft.position.set(-2.5, 0, 0) // Adjust position as needed
doorRight.position.set(2.5, 0, 0) // Adjust position as needed

scene.add(doorLeft)
scene.add(doorRight)

cam.position.z = 15 // Adjust based on your scene's size


let doorsOpened = false
let doorsSlided = false

const animate = function () {
  requestAnimationFrame(animate)

  // Rotate doors around their vertical edges to open
  if (!doorsOpened) {
    if (doorLeft.rotation.y > -Math.PI / 2) {
      doorLeft.rotation.y -= 0.01 // Adjust speed as needed
      doorRight.rotation.y += 0.01 // Adjust speed as needed
    } else {
      doorsOpened = true // Indicate that doors have finished opening
    }
  }
  
  // After doors have opened, slide them away
  if (doorsOpened && !doorsSlided) {
    if (doorLeft.position.x > -10) { // Adjust target positions as needed
      doorLeft.position.x -= 0.05 // Adjust speed as needed
      doorRight.position.x += 0.05 // Adjust speed as needed
    } else {
      doorsSlided = true // Indicate that doors have finished sliding
    }
  }

  // Move camera forward
  if (cam.position.z > 3) {
    cam.position.z -= 0.05
  }

  renderer.render(scene, cam)
}

const make_appear = () => {
  const par = document.querySelector('.intro')
  console.log('here it is: ', par)
  par.style.opacity = "1"
  par.style.transition = "all 3s"
}

const titleAnim = () => {
  console.log("Function executed after DOM is ready")
  const wellcome = document.querySelector('.title_container-text-message')
  wellcome.style.fontSize = "4em"
  wellcome.style.transition = "all 3s"
}

/* const animateArrow = () => {
  const arrowBox = document.querySelector('.arrow_container')
  const arrow = document.querySelector('.clipped-box')
  arrow.style.opacity = "1"
  arrow.style.transform = "rotateZ(90deg) rotateY(0)"
  arrowBox.style.top = "85%"
} */


const animateArrowOnScroll = () => {
  const arrowBox = document.querySelector('.arrow_container')
  const arrow = document.querySelector('.clipped-box')
  const arrowBoxTop = arrowBox.getBoundingClientRect().top
  const windowHeight = window.innerHeight

  if (arrowBoxTop < windowHeight * 0.95) {
    // If the arrow container is 75% visible in the viewport, trigger animation
    arrow.style.opacity = "1"
    arrow.style.transform = "rotateZ(90deg) rotateY(0)"
    arrowBox.style.top = "95%"
  } else {
    // Reset the animation if the arrow container is not sufficiently visible
    arrow.style.opacity = "0"
    arrow.style.transform = "rotateZ(90deg) rotateY(70deg)"
    arrowBox.style.top = "65%"
  }
}



animate()

setTimeout(make_appear, 5300)
setTimeout(titleAnim, 5400)
/* setTimeout(animateArrow, 5500) */
setTimeout(() => {
  window.addEventListener('scroll', animateArrowOnScroll)
}, 5500)






/* function updateDoorPositions() {
  const scrollY = window.scrollY; // Get the current vertical scroll position
  const maxScroll = document.body.scrollHeight - window.innerHeight; // Calculate the maximum scroll value
  
  // Calculate the progress of scrolling as a value between 0 and 1
  const scrollProgress = scrollY / maxScroll;
  
  // Adjust the positions of the doors based on scroll progress
  // Initially, doors are set at -2.5 and 2.5 on the X axis. You can adjust these values based on your scene's scale
  const doorMovementRange = 15; // This value controls how far the doors move. Adjust as necessary.
  doorLeft.position.x = -2.5 - doorMovementRange * scrollProgress; // Move left door further left
  doorRight.position.x = 2.5 + doorMovementRange * scrollProgress; // Move right door further right
}

// Listen for scroll events
window.addEventListener('scroll', updateDoorPositions);

// Initial call to set the door positions correctly on load
updateDoorPositions(); */